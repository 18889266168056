import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import AccessDenied from 'components/auth/access-denied';

const ClubPrivateContent = ({ clubId, messageTranslationKey, onAuthorise }) => (
  <>
    <Head>
      <title>Access Denied</title>
    </Head>
    <AccessDenied
      clubId={clubId}
      onAuthorise={onAuthorise}
      messageTranslationKey={messageTranslationKey}
    />
  </>
);

ClubPrivateContent.defaultProps = {
  messageTranslationKey: 'common:access_denied',
};

ClubPrivateContent.propTypes = {
  clubId: PropTypes.number.isRequired,
  messageTranslationKey: PropTypes.string,
  onAuthorise: PropTypes.func.isRequired,
};

export default ClubPrivateContent;
