import React from 'react';
import HeaderContext from './context';

const NoBackground = () => (
  <HeaderContext.Consumer>
    {({ clear }) => {
      clear();
    }}
  </HeaderContext.Consumer>
);

export default NoBackground;
