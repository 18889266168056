import React from 'react';
import PropTypes from 'prop-types';
import Club404 from './club-404';
import ClubPrivateContent from './club-private-content';
import DataLoadingError from './data-loading-error';

const PageDataLoadError = ({
  accessDeniedTranslationKey,
  clubId,
  onAuthorise,
  reload,
  statusCode,
}) => {
  if (statusCode === 404) {
    return <Club404 />;
  }

  if (statusCode === 403) {
    return (
      <ClubPrivateContent
        clubId={clubId}
        onAuthorise={onAuthorise}
        messageTranslationKey={accessDeniedTranslationKey}
      />
    );
  }

  return <DataLoadingError clubId={clubId} reload={reload} />;
};

PageDataLoadError.defaultProps = {
  accessDeniedTranslationKey: 'common:access_denied',
  error: null,
  onAuthorise: () => {},
  reload: null,
  statusCode: null,
};

PageDataLoadError.propTypes = {
  accessDeniedTranslationKey: PropTypes.string,
  clubId: PropTypes.number.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  onAuthorise: PropTypes.func,
  reload: PropTypes.func,
  statusCode: PropTypes.number,
};

export default PageDataLoadError;
